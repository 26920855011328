import React, { useCallback, useState } from "react"

const GiscusComments = ({ slug }) => {
  const [loadComments, setLoadComments] = useState(false)

  const COMMENTS_ID = "comments-container"

  const LoadComments = useCallback(() => {
    const script = document.createElement("script")
    script.src = "https://giscus.app/client.js"
    script.setAttribute("data-repo", "Nikhil1920/site-comments")
    script.setAttribute("data-repo-id", "R_kgDOHNmUGw")
    script.setAttribute("data-category", "study-site-comments")
    script.setAttribute("data-category-id", "DIC_kwDOHNmUG84CPFqJ")
    script.setAttribute("data-mapping", slug)
    script.setAttribute("data-reactions-enabled", "1")
    script.setAttribute("data-emit-metadata", "0")
    script.setAttribute("data-theme", "dark")
    script.setAttribute("crossorigin", "anonymous")
    script.async = true

    // console.log(siteMetadata)
    // console.log("Repo : " + siteMetadata.comment.giscusConfig.repo)

    const comments = document.getElementById(COMMENTS_ID)
    if (comments) comments.appendChild(script)

    return () => {
      const comments = document.getElementById(COMMENTS_ID)
      if (comments) comments.innerHTML = ""
    }
  }, [slug])
  return (
    <div className=" mt-8 mb-8">
      {!loadComments && (
        <div className="center">
          <button
            onClick={() => {
              LoadComments()
              setLoadComments(true)
            }}
            className="notification-button"
          >
            Load Comments
          </button>
        </div>
      )}
      <div className="giscus" id={COMMENTS_ID} />
    </div>
  )
}

export default GiscusComments
